<template>
  <v-container
  id="regular-tables"
  fluid
  tag="section"
  >



  <base-material-card

  color="success"
  icon="mdi-clipboard-plus"
  v-if = "usertype == 'admin'"
  title="Create NewsLetter"
  class="px-5 py-3"
  >
  
  <v-container class="py-0">
    <v-row>
  <v-col
      cols="8"
      md="8"
      >
      <v-col
      cols="12"
      md="12"
      >
      <v-text-field
      label="Title"
      class="purple-input"
      outlined
      dense
      v-model="NLetter.Title"
      
      />
    </v-col>

    <v-col
      cols="12"
      md="12"
      >
      <v-text-field
      label="Sub Title"
      v-model="NLetter.SubTitle"
      class="purple-input"
      outlined
      dense
      
      />
    </v-col>
</v-col>

    <v-col
      cols="4"
      md="4"
      >
      <v-img
  max-height="150"
  contain
  @click="onPickFile"
  :src="NLetter.Image"
></v-img>

<input 
type="file" 
style="display: none" 
ref="fileinput" 
accept="image/*"
@change="onfilepicked">
      </v-col>
    <v-col
    cols="12"
    md="12"
    >
    <client-only>
  <vue-editor v-model="NLetter.HTMLTEXT" />
</client-only>

  </v-col>


</v-row>
<template>
  <v-btn
  color="success"
  class="mr-0"
  @click="InsertItemToDB"
  >
  Save
</v-btn>
</template>



</v-container>
</base-material-card>

<base-material-card
icon="mdi-clipboard-text"
class="px-5 py-3"
>
<v-data-table
:headers="headers"
:items="NewsLetters"
sort-by="calories"
class="elevation-1"
>
<template v-slot:top>
  <v-toolbar
  flat
  >
  <v-toolbar-title>NewsLetter List</v-toolbar-title>
  <v-divider
  class="mx-4"
  inset
  vertical
  ></v-divider>
  <v-spacer></v-spacer>
  <v-dialog
  v-model="dialog"
  max-width="1000px"
  >
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
            <v-row>
  <v-col
      cols="8"
      md="8"
      >
      <v-col
      cols="12"
      md="12"
      >
      <v-text-field
      label="Title"
      class="purple-input"
      outlined
      dense
      v-model="editedItem.Title"
      
      />
    </v-col>

    <v-col
      cols="12"
      md="12"
      >
      <v-text-field
      label="Sub Title"
      class="purple-input"
      outlined
      dense
      v-model="editedItem.SubTitle"
      
      />
    </v-col>
</v-col>

    <v-col
      cols="4"
      md="4"
      >
      <v-img
  max-height="150"
  contain

  :src="editedItem.Image"
></v-img>
      </v-col>
    <v-col
    cols="12"
    md="12"
    >
    <client-only>
  <vue-editor v-model="editedItem.HTMLTEXT" />
</client-only>

  </v-col>


</v-row>
</v-container>
</v-card-text>

<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
  color="blue darken-1"
  text
  @click="close"
  >
  Cancel
</v-btn>
<v-btn
color="blue darken-1"
text
@click="save"
>
Save
</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<v-dialog v-model="dialogDelete" max-width="1000px">
  <v-card>
    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-toolbar>
</template>
<template v-slot:item.actions="{ item }">
  <v-icon
  small
  class="mr-2"
  @click="editItem(item)"
  >
  mdi-pencil
</v-icon>
<v-icon
small
@click="deleteItem(item)"
>
mdi-delete
</v-icon>
</template>
<template v-slot:no-data>
  <v-btn
  color="primary"
  @click="initialize"
  >
  Reset
</v-btn>
</template>

<template v-slot:item.Date="{ item }">

        {{ format_date(item.Date) }}

</template>


</v-data-table>
</base-material-card>


<div class="py-3" />

</v-container>
</template>

<script>

  import { db ,fb } from '@/db'
  import { mapMutations, mapState } from 'vuex'
 import { VueEditor } from "vue2-editor";

  export default {
    components: {
    VueEditor
    },
    data () {
      return {
        NLetter :{
          Title : '',
          Subtitle : '',
          Image : 'https://cdn-icons-png.flaticon.com/512/685/685685.png',
          HTMLTEXT : '',
          Date : new Date()
        },
        FilePickImage : '',
        ItemDetails : [],
        collapseDuration: 0,
        headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'Title',
        },
        { text: 'Date', value: 'Date' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        form : {
          Images : [] ,
          snackbar : false,
          snakMsg : "Uploaded success"
        },
        Finalitem :{
          Salary : 0.0,
          IsActive : true,
          description : '',
          afterInterview : true
        },
        ImageFire: [],
        dialog: false,
        dialogDelete: false,
        search: '',
        desserts: [],
        editedIndex: -1,
        editedItem: {
        },
        defaultItem: {
        },

      }
    },
    computed: {

    NewsLetters(){
          return this.$store.state.NewsLetters   
    },

      usertype(){
        return this.$store.state.userType
      },
      formTitle () {
        return 'NewsLetter'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        
        val || this.closeDelete()
      },
    },

    methods: {
    toggleDetails (item , index) {
      this.$set(this.MainItem[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    InsertItemToDB(){
      var item  = this.NLetter;
      var context = this; 
      var imagedata = item.Image 
      item.Date = new Date();
      item.Image = ''
      db.collection('Newsletter')
      .add( { ...item} )
      .then(docRef =>{
      var uuid = require("uuid");
      
      var storageRef = fb.storage().ref('Newsletter/' + docRef.id +'/'+ uuid.v4())
      let UploadTask = storageRef.put(context.FilePickImage);

      UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {
            
          },function() {
            
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
              
                return db.collection('Newsletter')
                .doc(docRef.id)
                .update({"Image" : downloadURL , ID : docRef.id})
                .then(_ => {  
                  alert("Newsletter has been added successfully");
                 context.NLetter.Title = ''
                 context.NLetter.Subtitle = ''
                 context.NLetter.HTMLTEXT = ''
                 context.NLetter.Image ='https://cdn-icons-png.flaticon.com/512/685/685685.png'
                })
              })
            })


      })
    },
    rowClick(item, row)
    {
      var found = false;
      for(var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].ItemName == item.ItemName) {
          found = true;
          break;
        }
      }
      if (!found){
        var newitem =  Object.assign({}, item);
        this.Finalitem.Cost =  this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
        this.selected.push(newitem);
      }else{
       alert("Item Already Used");
     }

   },
   filterOnlyCapsText (value, search, item) {
    return value != null &&
    search != null &&
    typeof value === 'string' &&
    value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
  },
  editItem (item) {
    
    this.editedIndex = this.NewsLetters.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  },

  deleteItem (item) {
    
    this.editedIndex = this.NewsLetters.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
  },
  deleteMainItem (item , index) {
    var context = this; 
     db.collection('NewsLetters').doc(item.ID)
     .delete().then(_=>{
      alert("NewsLetter Was Deleted ...")
      context.MainItem.splice(index);
    })

 },
 deleteItemConfirm () {
  
  db.collection('Newsletter').doc(this.NewsLetters[this.editedIndex].id)
     .delete().then(_=>{
      alert("NewsLetter Was Deleted ...")
      context.MainItem.splice(index);
    })
  this.closeDelete()
},

close () {
  this.dialog = false
  this.$nextTick(() => {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.editedIndex = -1
  })
},

closeDelete () {
  
  this.dialogDelete = false
  this.deleteMainItem(this.editedItem,this.editedIndex);
  this.editedItem = Object.assign({}, this.defaultItem)
  this.editedIndex = -1
},
      format_date : function(value){
       
            let myDate = new Date(value.seconds * 1000)
            var now = new Date()

          
            if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth() 
              && myDate.getFullYear() == now.getFullYear()) )
             { 
              let options = {   
                   weekday: "long", year: "numeric", month: "short",  day: "numeric" 
              };  
              return myDate.toLocaleDateString("en-us", options)
              }
            else
            {
              let options = {   
                hour: "2-digit", minute: "2-digit"  
              };  
              return ("Today , " + myDate.toLocaleTimeString("en-us", options))
            }
            
           
          
      },

save () {
  
  if (this.editedIndex > -1) {
     db.collection('Newsletter').doc(this.editedItem.ID)
     .update(this.editedItem).then(_=>{
      alert("Newsletter Was Updated ...")
    })

  } else {
    this.selected.push(this.editedItem)
  }
  this.close()


},

onPickFile(){
  this.$refs.fileinput.click();
},
ondelete(index){
  this.form.Images.splice( index, 1 );

},
onfilepicked(event){
  const files = event.target.files
  var context = this;
  let filename = files[0].name
  if (filename.lastIndexOf('.') <= 0){
    return alert('please add a valid file !')
  }


  const reader = new FileReader()
  reader.addEventListener('load' , () => {
    
   context.NLetter.Image = reader.result
 })
  reader.readAsDataURL(files[0])
  this.FilePickImage = files[0]
},

},
created(){
  this.$store.dispatch('bindNewsLettersDash');

}
}
</script>


<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
